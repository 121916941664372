import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      check_in: JSON.parse(localStorage.getItem("check_in")),
      cancelTokens: [],
    };
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
  },
  mutations: {
    updateUser(state, newUser) {
      localStorage.setItem("user", JSON.stringify(newUser));
      state.user = newUser;
    },
    updateCheckIn(state, newCheckIn) {
      localStorage.setItem("check_in", JSON.stringify(newCheckIn));
      state.check_in = newCheckIn;
    },
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    },
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request) => {
        if (request.cancel) {
          request.cancel();
        }
      });

      // Reset the cancelTokens store
      context.commit("CLEAR_CANCEL_TOKENS");
    },
  },
});

export default store;
