import mustBeAuthenticated from "@/middleware/mustBeAuthenticated";
import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/",
    component: () => import("@/Layouts/main/main.vue"),
    beforeEnter: mustBeAuthenticated,
    children: [
      {
        path: "",
        component: () => import("@/views/medical.vue"),
        meta: {
          title: "Home",
        },
      },
      {
        path: "doctor",
        component: () => import("@/views/doctor/index.vue"),
        meta: {
          title: "Doctor",
        },
      },
      {
        path: "restaurant",
        component: () => import("@/views/restaurant.vue"),
        meta: {
          title: "Restaurant",
        },
      },
      {
        path: "history",
        component: () => import("@/views/restaurantHistory.vue"),
        meta: {
          title: "Restaurant",
        },
      },
      {
        path: "vod",
        component: () => import("@/views/vod.vue"),
        meta: {
          title: "Video On Demand",
        },
      },
      {
        path: "vod/:type/:id",
        component: () => import("@/views/vodDetail.vue"),
        meta: {
          title: "Video On Demand",
        },
      },
      {
        path: "facility",
        component: () => import("@/views/facility/facility.vue"),
        meta: {
          title: "Fasilities",
        },
      },
      {
        path: "facility/detail/:id",
        component: () => import("@/views/facility/detail.vue"),
        meta: {
          title: "Fasilities",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch("CANCEL_PENDING_REQUESTS");
  next();
});

export default router;
