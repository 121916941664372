import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "remixicon/fonts/remixicon.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Toaster from "@meforma/vue-toaster";

import $axios from "./api";
import store from "./store/index";

const app = createApp(App)
  .use(router)
  .use(VueSweetalert2)
  .use(store)
  .use(Toaster, {
    position: "top-right",
    dismissible: true,
    pauseOnHover: true,
  });

app.config.globalProperties.$axios = { ...$axios };

app.mount("#app");
