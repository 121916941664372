<template>
  <RouterView />
</template>
<script>
export default {
  mounted() {
    // document.body.style.zoom = 1 / window.devicePixelRatio;
  },
};
</script>
